<template>
    <div class="pagination-container" v-if="pages > 1">
        <div :class="['pagination-item', {  active : currentPage == item }]" v-for="(item, idx) in pages" :key="idx" @click="$emit('setPage', item)">{{ item }}</div>
    </div>
</template>

<script>
export default {
    name : "Pagination",
    props : ["currentPage", "pages"]
}
</script>

<style scoped lang="scss">
    .pagination-container {
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .pagination-item {
        height:20px;
        width:20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        margin:5px;
        transition:0.5s;
        cursor: pointer;
    }

    .pagination-item:hover , .pagination-item.active {
        color:$mainColor;
    }
</style>