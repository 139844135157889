<template>
  <div class="main">
    <h1>{{$t("news.pressReview")}}</h1>
    <blog :cat="[4]"></blog>
  </div>
</template>

<script>
import Blog from "./../components/Blog.vue";

export default {
  name: "Press",
  components : { Blog}
}
</script>

<style scoped lang="scss">

h1 {
  color:$mainColor;
  font-size:35px;
  font-weight:400;
}

.main {
  width:$blog;
  max-width: $max;
  margin:40px auto;
}
</style>